export const pagination = () => {
    return {
        page: 1,
        pageCount: 1,
        pageSize: 10,
        prefix ({ itemCount }) {
            return `Total ${itemCount} registros`
        }
    }
}

export const query = () => {
    return {
        search: '',
        sorter: null,
        filter: null,
        createdAt: null,
        optionalQueryString: null
    }
}
