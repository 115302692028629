import store from '@/store'

const isAuthenticatedGuard = async( to, from, next ) => {

    const { ok } = await store.dispatch('auth/checkAuthentication')

    if (ok && to.name === 'login')
        next({ name: 'dashboard' })
    else if (ok)
        next()
    else if (!ok && to.name === 'login')
        next()
    else
        next({ name: 'login' })
}

export default isAuthenticatedGuard
