export const isLoading = (state, loading) => state.isLoading = loading

export const clearData = (state) => {
    state.invoice = {
        warehouse: 1,
        is_proforma: false,
        currency: null,
        sale_condition: '01',
        credit_term: null,
        customer: null,
        payment_methods: [],
        details: [],
        discount: 0,
        subtotal: 0,
        tax: 0,
        total: 0
    }

    state.details.isLoading = false
    state.details.items = null
    state.detailSelected = null
    state.item = null
}

export const addDetail = (state, detail) => {
    if (detail.order) {
        const d = state.invoice.details.find(i => i.order.id === detail.order?.id)
        if (d) {
            d.amount = detail.order.amount_by_invoice
            d.order = {...detail.order}
            calculate(state)
            return
        }
    }

    state.invoice.details = [detail, ...state.invoice.details]
    state.detailSelected = detail
    calculate(state)
}

export const removeDetail = (state, detail) => {
    const index = state.invoice.details.findIndex(i => i.id === detail.id)
    if (index >= 0) {
        state.invoice.details.splice(index, 1)
        calculate(state)
    }
}

export const removeDetailByOrder = (state, orderId) => {
    const index = state.invoice.details.findIndex(i => i.order.id === orderId)
    if (index >= 0) {
        state.invoice.details.splice(index, 1)
        calculate(state)
    }
}

export const updateDetailSelected = (state, detail) => state.detailSelected = detail

export const updateCurrency = (state, currency) => state.invoice.currency = currency

export const updateSaleCondition = (state, saleCondition) => state.invoice.sale_condition = saleCondition

export const updateCreditTerm = (state, creditTerm) => state.invoice.credit_term = creditTerm

export const updateDetailAmount = (state, amount) => {
    state.detailSelected.amount = amount
    calculate(state)
}

export const updatePrice = (state, price) => {
    state.detailSelected.price = price
    calculate(state)
}

export const updateDiscount = (state, { discount, isApplyToPrice }) => {
    if (isApplyToPrice) {
        state.detailSelected.price -= parseFloat(discount)
        state.detailSelected.discount = 0
    } else {
        // state.detailSelected.price -= state.detailSelected.product.sale_price
        state.detailSelected.discount = discount || 0
    }

    calculate(state)
}

export const updateCustomer = (state, customer) => state.invoice.customer = customer

export const updatePaymentMethods = (state, paymentMethods) => state.invoice.payment_methods = paymentMethods

export const updateInvoiceType = (state, is_proforma = false) => state.invoice.is_proforma = is_proforma

const calculate = (state) => {
    const { invoice } = state

    invoice.details.forEach(d => {
        const discount = calculateDiscount(d)
        d.subtotal = d.price * d.amount - discount
        d.tax = d.subtotal * (d.product.tax / 100)
        d.total = d.subtotal + d.tax
    })

    if (invoice.details.length > 0) {
        invoice.discount = invoice.details.map(d => calculateDiscount(d)).reduce((prev, next) => prev + next)
        invoice.subtotal = invoice.details.map(d => d.subtotal).reduce((prev, next) => prev + next)
        invoice.tax = invoice.details.map(d => d.tax).reduce((prev, next) => prev + next)
        invoice.total = invoice.details.map(d => d.total).reduce((prev, next) => prev + next)
    } else {
        invoice.discount = 0
        invoice.subtotal = 0
        invoice.tax = 0
        invoice.total = 0
    }
}

const calculateDiscount = (detail) => detail.price * (detail.discount / 100) * detail.amount

export const setItem = (state, item) => state.item = item

export const isLoadingDetails = (state, loading) => state.details.isLoading = loading

export const setDetails = (state, items) => state.details.items = items

export const setProformaToInvoice = (state, proforma) => state.proformaToInvoice = proforma

export const setControlFocus = (state, control) => state.controlFocus = control

export const onControlFocus = (state) => state.controlFocus?.focus()
