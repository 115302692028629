import { pagination, query } from '@/helpers/paginationUtils'

export default () => ({
    endpoint: '/v1/doc-elect',
    isLoading: true,
    items: {
        results: []
    },
    item: null,
    isCreateOrEdit: false,
    mapModelValidation: [],
    pagination: pagination(),
    query: query()
})
