import { pagination, query } from '@/helpers/paginationUtils'

export default () => ({
    endpoint: '/v1/customers-providers',
    isLoading: true,
    items: {},
    itemsAll: [],
    item: null,
    isCreateOrEdit: false,
    mapModelValidation: [
        { title: 'Nombre', key: 'name' },
        { title: 'Identificación', key: 'identification' },
        { title: 'Tipo de identificación', key: 'identification_type' },
    ],
    pagination: pagination(),
    query: query()
})
