export default {
    name: 'user',
    path: 'users',
    children: [
        {
            path: '',
            name: 'user-view',
            component: () => import(/* webpackChunkName: "UserView" */ '@/modules/user/views/UserView.vue'),
        },
        {
            path: '',
            name: 'user-profile-view',
            component: () => import(/* webpackChunkName: "UserProfileView" */ '@/modules/user/views/UserProfileView.vue'),
        }
    ]
}
