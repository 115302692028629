export default {
    name: 'inventory',
    path: 'inventories',
    children: [
        {
            path: '',
            name: 'inventory-list',
            component: () => import(/* webpackChunkName: "InventoryMovementView" */ '@/modules/inventory/views/InventoryMovementView.vue'),
        },
        {
            path: 'create',
            name: 'inventory-create',
            component: () => import(/* webpackChunkName: "ProformaView" */ '@/modules/inventory/views/InventoryMovementCreateOrEditView.vue'),
        },
        {
            path: 'edit/:id:',
            name: 'inventory-detail',
            component: () => import(/* webpackChunkName: "InvoiceDetail" */ '@/modules/inventory/views/InventoryMovementCreateOrEditView.vue'),
        }
    ]
}
