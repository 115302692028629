export const isLoading = (state) => state.isLoading
export const getEndpoint = (state) => state.endpoint
export const getTableSelected = (state) => state.tableSelected
export const getDetailSelected = (state) => state.detailSelected
export const getItem = (state) => state.item
export const getItems = (state) => state.items
export const getItemsToInvoice = (state) => {
    const items = []
    state.items.forEach(i => i.details.forEach(d => items.push({ ...d })))
    return items
}
