import state from './state'
import * as actions from '@/composables/actions'
import * as getters from '@/composables/getters'
import * as mutations from '@/composables/mutations'


const warehouseModule = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default warehouseModule
