<template>
        <n-layout position="absolute">
            <n-layout-header style="height: 55px; padding: 10px" bordered>
                <n-space justify="space-between" align="center">
                    <span class="logo">
                        <n-text type="info">{{ appTitle }}</n-text>
                        <n-text v-if="user"> | {{ user.company.name || ''}}</n-text>
                    </span>
                    <n-space align="center" v-if="user">
                        <n-icon>
                            <sunny-icon/>
                        </n-icon>
                        <n-switch v-model:value="isDarkTheme" @update:value="onChangeTheme"/>
                        <n-icon>
                            <moon-icon/>
                        </n-icon>
                        <n-text type="info">Sucursal</n-text>
                        <n-select v-model:value="warehouseSelected"
                                  :options="warehousesOptions"
                                  @update:value="onWarehouseChange"
                                  :style="{ minWidth: '180px' }"/>

                        <n-dropdown :options="userOptions" @select="onUserOption">
                            <n-button>{{ user.first_name || user.username }}</n-button>
                        </n-dropdown>
                    </n-space>
                </n-space>
            </n-layout-header>
            <n-layout has-sider position="absolute" style="top: 55px;" :native-scrollbar="false">
                <n-layout-sider
                        bordered
                        show-trigger
                        collapse-mode="width"
                        :collapsed-width="64"
                        :width="240"
                        :native-scrollbar="false"
                        style="height: 94vh">
                    <n-menu
                        :collapsed-width="64"
                        :collapsed-icon-size="22"
                        :options="menuOptions"/>
                </n-layout-sider>
                <n-layout content-style="padding: 24px;">
                    <router-view/>
                </n-layout>
            </n-layout>
        </n-layout>
</template>

<script>
import {h, defineComponent, ref} from "vue";
import {RouterLink} from "vue-router"
import {
    PersonCircleOutline as UserIcon,
    LogOutOutline as LogoutIcon,
    CardOutline as CardIcon,
    StatsChartOutline as StatsChartIcon,
    PeopleOutline as PeopleIcon,
    CubeOutline as CubeIcon,
    CogOutline as CogIcon,
    DocumentOutline as DocumentIcon,
    ArchiveOutline as ArchiveIcon,
    SunnyOutline as SunnyIcon,
    MoonOutline as MoonIcon,
    ListOutline as ListIcon,
} from "@vicons/ionicons5";
import useAuth from '@/modules/auth/composables/useAuth'
import {useRouter} from 'vue-router'
import {renderIcon} from '@/helpers/utils'

const appTitle = process.env.VUE_APP_TITLE
const menuOptions = [
    {
        label: () => h(
            RouterLink,
            {to: {name: "dashboard"}},
            {default: () => "Dashboard"}
        ),
        key: "dashboard",
        icon: renderIcon(StatsChartIcon)
    },
    {
        label: "Facturación",
        key: "invoice-group",
        icon: renderIcon(CardIcon),
        children: [
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "invoice-create"}},
                    {default: () => "Facturar"}
                ),
                key: "invoice"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "invoice-history"}},
                    {default: () => "Historial"}
                ),
                key: "invoice-history"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "proforma"}},
                    {default: () => "Proformas"}
                ),
                key: "proforma"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "invoice-accounting-register"}},
                    {default: () => "Cierre de caja"}
                ),
                key: "invoice-accounting-register"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "doc-elect"}},
                    {default: () => "Doc. electrónicos"}
                ),
                key: "doc-elect"
            }
        ]
    },
    {
        label: "Ordenes",
        key: "order-group",
        icon: renderIcon(ArchiveIcon),
        children: [
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "orders"}},
                    {default: () => "Crear orden"}
                ),
                key: "orders"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "make"}},
                    {default: () => "Preparación"}
                ),
                key: "make"
            }
        ]
    },
    {
        label: "Inventario",
        key: "inventory",
        icon: renderIcon(ListIcon),
        children: [
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "inventory-list"}},
                    {default: () => "Movimientos"}
                ),
                key: "inventory-list"
            }
        ]
    },
    {
        label: () => h(
            RouterLink,
            {to: {name: "customer-provider"}},
            {default: () => "Clientes/Proveedores"}
        ),
        key: "customer-provider",
        icon: renderIcon(PeopleIcon)
    },
    {
        label: 'Productos',
        key: 'product-group',
        icon: renderIcon(CubeIcon),
        children: [
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "product-view"}},
                    {default: () => "Productos"}
                ),
                key: "product"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "product-type-view"}},
                    {default: () => "Categorías"}
                ),
                key: "product-type"
            }
        ]
    },
    {
        label: "Reportes",
        key: "report-group",
        icon: renderIcon(DocumentIcon),
        children: [
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "invoice-report"}},
                    {default: () => "Facturación"}
                ),
                key: "invoice-report"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "iva-report"}},
                    {default: () => "Valor Agregado (IVA)"}
                ),
                key: "iva-report"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "inventory-report"}},
                    {default: () => "Inventario"}
                ),
                key: "inventory-report"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "sale-report"}},
                    {default: () => "Ventas"}
                ),
                key: "sale-report"
            }
        ]
    },
    {
        label: "Configuración",
        key: "config-group",
        icon: renderIcon(CogIcon),
        children: [
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "company-view"}},
                    {default: () => "Compañia"}
                ),
                key: "company"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "discount-view"}},
                    {default: () => "Descuentos"}
                ),
                key: "discount"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "warehouse-view"}},
                    {default: () => "Sucursales"}
                ),
                key: "warehouse"
            },
            {
                label: () => h(
                    RouterLink,
                    {to: {name: "user-view"}},
                    {default: () => "Usuarios"}
                ),
                key: "user"
            }
        ]
    }
];

const userOptions = [
    {
        label: "Cuenta",
        key: "profile",
        icon: renderIcon(UserIcon)
    },
    {
        label: "Cerrar sesión",
        key: "logout",
        icon: renderIcon(LogoutIcon)
    }
]

export default defineComponent({
    components: {
        SunnyIcon,
        MoonIcon
    },
    setup() {
        const router = useRouter()
        const {user, warehouses, warehouseCurrent, updateWarehouseCurrent, updateTheme, logout, theme} = useAuth()
        const warehouseSelected = ref(warehouseCurrent.value.id)

        const isDarkTheme = ref(theme.value !== null)
        return {
            appTitle,
            warehouseSelected,
            isDarkTheme,
            user,
            warehouseCurrent,
            warehousesOptions: warehouses.value.map(w => ({label: w.name, value: w.id})),
            menuOptions,
            userOptions,
            onUserOption: (key) => {
                switch (key) {
                    case 'logout':
                        router.push({name: 'login'})
                        logout()
                        break
                    case 'profile':
                        router.push({name: 'user-profile-view'})
                        break
                }
            },
            onWarehouseChange: (val) => updateWarehouseCurrent(val),
            onChangeTheme: () => updateTheme(isDarkTheme.value ? 'dark' : 'light')
        }
    }
});
</script>

<style scoped>
.logo {
    font-size: 18px;
    padding: 2px;
    margin-left: 20px;
    margin-right: 50px;
    float: left;
    font-weight: bold;
}

.n-layout-header {
    height: 60px;
    padding: 10px 20px;
}
</style>
