export const isLoading = (state, loading) => state.isLoading = loading

export const updateOrder = (state, item) => state.item = item

export const terminateOrder = (state, numTable) => {
    const index = state.items.findIndex(i => i.table === numTable)
    if (index >= 0) {
        state.items.splice(index, 1)
    }

    state.item = null
    state.tableSelected = null
    state.detailSelected = null
}

export const tableSelected = (state, table) => {
    state.tableSelected = table
    let order = state.items.find(o => o.table === table)
    if (!order) {
        order = { table, details: [] }
        state.items.push(order)
    }

    state.item = order
}

export const deleteOrder = (state, id) => {
    let index = state.items.findIndex(o => o.id === id)
    state.items.splice(index, 1)

}

export const addDetail = (state, detail) => {
    if (state.item) {
        state.item.details = [detail, ...state.item.details]
    }
}

export const setDetail = (state, detail) => state.detailSelected = detail

export const deleteDetail = (state, detailId) => {
    const order = state.item
    let index = order.details.findIndex(o => o.id === detailId)
    order.details.splice(index, 1)

}

export const setItems = (state, item) => state.items = item

export const addInvoice = (state, {detail, amount, to_invoice}) => {
    detail.to_invoice = to_invoice
    detail.amount_by_invoice = to_invoice ? amount : 0
}

