export const isLoading = (state) => state.isLoading
export const getItem = (state) => state.item
export const getInvoice = (state) => state.invoice
export const getDetails = (state) => state.details

export const getDetailSelected = (state) => state.detailSelected

export const getProformaToInvoice = (state) => state.proformaToInvoice

export const getMapModelValidation = (state) => state.mapModelValidation
