export const getEndpoint = ( state ) => state.endpoint

export const getItems = ( state ) => state.items

export const getItemsAll = ( state ) => state.itemsAll

export const getItem = ( state ) => {
    return state.item ? { ...state.item } : state.item
}

export const getPagination = ( state ) => state.pagination

export const isLoading = ( state ) => state.isLoading

export const getQuery = ( state ) => state.query

export const isCreateOrEdit = ( state ) => state.isCreateOrEdit

export const getMapModelValidation = ( state ) => state.mapModelValidation


