export const formatNumber = (num) => {
    return num == null ? '' : parseFloat(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
}

export const formatNumberWithPad = (num, space, prefix= '')  => {
    let n = `${prefix}${formatNumber(num)}`
    let charSpace = ''
    while (charSpace.length < (space - n.length)) charSpace += '_'
    return `<span style="color: transparent;">${charSpace}</span>${n}`
}

export const parseNumber = (input) => {
    const nums = input.replace(/,/g, '').trim()
    if (/^\d+(\.(\d+)?)?$/.test(nums))
    return Number(nums)
    return nums === '' ? null : Number.NaN
}

export const roundCustom = (value) => {
    // value = Math.round(value);
    value = value.toString().split(".")[0]
    const digitLast = value.toString().slice(-1);
    let digitSumOrSubtractWith = 0;
    switch (digitLast) {
        case '1':
            digitSumOrSubtractWith = -1;
            break;
        case '2':
            digitSumOrSubtractWith = -2;
            break;
        case '3':
            digitSumOrSubtractWith = 2;
            break;
        case '4':
            digitSumOrSubtractWith = 1;
            break;
        case '6':
            digitSumOrSubtractWith = -1;
            break;
        case '7':
            digitSumOrSubtractWith = -2;
            break;
        case '8':
            digitSumOrSubtractWith = 2;
            break;
        case '9':
            digitSumOrSubtractWith = 1;
            break;
    }

    return parseInt(value) + digitSumOrSubtractWith;

}
