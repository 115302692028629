

export default () => ({
    endpoint: '/v1/orders',
    isLoading: false,
    item: null,
    items: [],
    tableSelected: null,
    detailSelected: null,
    mapModelValidation: [
        { title: 'Mesa', key: 'table' },
        { title: 'Cantidad', key: 'amount' },
    ]
})
