import axios from 'axios'

const coreApi = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
})

coreApi.interceptors.request.use( (config) => {

    config.headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
        'X-WAREHOUSE-ID': parseInt(localStorage.getItem('warehouse'))
    }

    return config
})

export default coreApi
