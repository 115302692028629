import { pagination, query } from '@/helpers/paginationUtils'

export default () => ({
    endpoint: '/v1/companies',
    isLoading: true,
    items: {
        results: []
    },
    item: null,
    isCreateOrEdit: false,
    mapModelValidation: [
        { title: 'Nombre', key: 'name' },
        { title: 'Nombre comercial', key: 'trade_name' },
        { title: 'Email', key: 'email' },
        { title: 'Teléfono', key: 'phone' },
        { title: 'Usuario', key: 'treasury.name' },
        { title: 'Contraseña', key: 'treasury.password' },
        { title: 'Pin', key: 'treasury.pin' },
        { title: 'Código de actividad', key: 'treasury.activity_code' },
        { title: 'Name', key: 'name' },
    ],
    pagination: pagination(),
    query: query()
})
