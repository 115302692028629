
import state from './state'
import * as getters from './getters'


const currencyModule = {
    namespaced: true,
    getters,
    state
}


export default currencyModule
