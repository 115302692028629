import {darkTheme} from 'naive-ui'

export const getAuthStatus = ( state ) => {
    return state.status
}

export const getUser = ( state ) => {
    return state.user
}

export const getWarehouseCurrent = ( state ) => {
    let warehouse = state.warehouseCurrent
    if (warehouse == null) {
        warehouse = state.user.warehouses[0]
    }

    return warehouse
}

export const getWarehouses = ( state ) => {
    if (state.user && state.user.warehouses) {
        return state.user.warehouses
    }

    return []
}

export const getTheme = (state) =>  {
    if (state.theme === 'light') {
        return null
    }

    return darkTheme
}

export const getConfig = (state) => {
    if (state.user && state.user.company && state.user.company.config) {
        return state.user.company.config
    }

    return null
}
