import { pagination, query } from '@/helpers/paginationUtils'

export default () => ({
    endpoint: '/v1/invoices',
    isLoading: true,
    items: {
        results: []
    },
    item: null,
    isCreateOrEdit: false,
    mapModelValidation: [
        { title: 'Nombre', key: 'name' },
        { title: 'Moneda', key: 'currency_id' },
        { title: 'Categoría', key: 'product_type_id' },
        { title: 'Precio de compra', key: 'purchase_price' },
        { title: 'Precio de venta', key: 'sale_price' },
        { title: 'Unidad de medida', key: 'unit_measure' }
    ],
    pagination: pagination(),
    query: query()
})
