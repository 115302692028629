export const isLoading = (state, loading) => state.isLoading = loading

export const setItems = (state, {data, page}) => {
    state.pagination.page = page
    state.pagination.pageCount = Math.ceil(data.results.length / state.pagination.pageSize)
    state.pagination.itemCount = data.count
    state.items = {...data}
}

export const setItemsAll = (state, data) => state.itemsAll = [...data]

export const setItem = (state, item) => state.item = item

export const cleanItemSelected = (state) => {
    state.item = null
    state.isCreateOrEdit = false
}

export const setShowCreateOrEdit = (state, value) => {
    state.isCreateOrEdit = value
}

export const setQuerySearch = (state, search) => {
    state.query.search = search
}

export const setQuerySorter = (state, sorter) => {
    state.query.sorter = sorter
}

export const setQueryFilter = (state, query) => {
    state.query.filter = query
}

export const setQueryCreatedAt = (state, range) => {
    state.query.createdAt = range
}

export const setOptionalQueryString = async (state, query) => {
    state.query.optionalQueryString = query
}

export const clearEntries = (state) => {
    state.items = {}
}
