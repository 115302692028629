import { computed } from 'vue'
import { useStore } from 'vuex'


const useAuth = () => {

    const store = useStore()

    const loginUser = async( user ) => await store.dispatch('auth/signInUser', user )
    const checkAuthStatus = async() => await store.dispatch('auth/checkAuthentication')
    const logout = () => store.commit('auth/logout')
    const updateWarehouseCurrent = (warehouseId) => store.commit('auth/updateWarehouseCurrent', warehouseId)
    const updateTheme = (theme) => store.commit('auth/theme', theme)

    const getConfig = (key) => {
        const configs = store.getters['auth/getConfig']
        if (configs) {
            const config = configs.find(c => c.key === key)
            if (config) {
                return config['value']
            }

        }

        return null
    }

    return {
        checkAuthStatus,
        loginUser,
        logout,
        updateWarehouseCurrent,
        updateTheme,
        getConfig,

        authStatus: computed(() => store.getters['auth/getAuthStatus']),
        user: computed(() => store.getters['auth/getUser']),
        warehouseCurrent: computed(() => store.getters['auth/getWarehouseCurrent']),
        warehouses: computed(() => store.getters['auth/getWarehouses']),
        theme: computed(() => store.getters['auth/getTheme']),
    }
}

export default useAuth
