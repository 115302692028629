import {h} from "vue";
import {NIcon, NTag} from "naive-ui";
import { datetimeF } from '@/helpers/dateUtils'

export const renderIcon = (icon) => {
    return () => h(NIcon, null, {default: () => h(icon)});
}

export const renderDateTime = (timestamp) => {
    // return timestamp ? dateHumanize(new Date(Date.parse(timestamp))) : ''
    return timestamp ? datetimeF(new Date(Date.parse(timestamp))) : ''
}

export const renderTag = (text, type = 'info', size = 'small') => {
    return text
        ? h(NTag, {size, type, round: false, bordered: false}, {default: () => text})
        : h(NTag, {size, type, round: false, bordered: false}, {default: () => 'Desconocido'})
}

export const renderBoolTag = (isActive = true) => {
    return renderTag(isActive ? 'Activo' : 'Inactivo', isActive ? 'success' : 'error')
}

export const renderIdentificationType = (identificationType) => {
    switch (identificationType) {
        case 'PHYSICAL': return renderTag('FÍSICA', 'success')
        case 'LEGAL': return renderTag('JURÍDICA', 'info')
        case 'DIMEX': return renderTag('DIMEX', 'warning')
        case 'NITE': return renderTag('NITE', 'error')
    }

    return ''
}

export const renderInvoiceStatusTag = (status) => {
    switch (status) {
        case 'DRAFT':
            return renderTag('BORRADOR', 'info')
        case 'INVOICED':
            return renderTag('FACTURADA', 'primary')
        case 'CANCELLED':
            return renderTag('ANULADA', 'warning')
        case 'PAID':
            return renderTag('PAGADA', 'success')
        case 'ERROR':
            return renderTag('ERROR', 'error')
        case 'ERROR_ELECT_DOC':
            return renderTag('ERROR DOC. ELECT.', 'warning')
        default:
            return renderTag(status, 'info')
    }
}

export const renderAccountingRegisterStatusTag = (status) => {
    switch (status) {
        case 'DRAFT':
            return renderTag('BORRADOR', 'info')
        case 'APPLIED':
            return renderTag('APLICADO', 'primary')
        case 'CANCELLED':
            return renderTag('ANULADO', 'warning')
        default:
            return renderTag(status, 'info')
    }
}

export const renderDocElectStatusTag = (status) => {
    switch (status) {
        case 'DRAFT':
            return renderTag('BORRADOR', 'info')
        case 'GENERATED':
            return renderTag('GENERADO', 'primary')
        case 'SENT':
            return renderTag('ENVIADO', 'primary')
        case 'PENDING':
            return renderTag('PENDIENTE', 'warning')
        case 'RECEIVED':
            return renderTag('RECIBIDO', 'info')
        case 'ACCEPTED':
            return renderTag('ACEPTADO', 'success')
        case 'REJECTED':
            return renderTag('RECHAZADO', 'error')
        case 'ERROR':
            return renderTag('ERROR', 'error')
        case 'DUPLICATE':
            return renderTag('DUPLICADO', 'warning')
        default:
            return renderTag(status.toUpperCase(), 'info')
    }
}

export const renderInvoiceTypeTag = (is_credit) => {
    return is_credit
        ? renderTag('CREDITO', 'info')
        : renderTag('CONTADO', 'primary')
}

export const renderDocElectTypeTag = (docType) => {
    switch (docType) {
        case 'ELECT_DOC_INVOICE':
            return renderTag('FACTURA', 'info')
        case 'ELECT_DOC_TICKET':
            return renderTag('TIQUETE', 'primary')
        case 'ELECT_DOC_CREDIT_NOTE':
            return renderTag('NOTA DE CREDITO', 'warning')
        case 'ELECT_DOC_RESPONSE':
            return renderTag('RESPUESTA', 'success')
        default:
            return renderTag(docType, 'info')
    }
}


export const renderInventoryTypeTag = (inventoryType) => {
    switch (inventoryType) {
        case 'SALE':
            return renderTag('VENTA', 'info')
        case 'PURCHASE':
            return renderTag('COMPRA', 'primary')
        case 'ADJUSTMENT':
            return renderTag('AJUSTE', 'warning')
        default:
            return renderTag(inventoryType, 'info')
    }
}

export const renderInventoryStatusTag = (status) => {
    switch (status) {
        case 'DRAFT':
            return renderTag('BORRADOR', 'info')
        case 'APPLIED':
            return renderTag('APLICADO', 'success')
        case 'CANCELLED':
            return renderTag('ANULADO', 'warning')
        default:
            return renderTag(status, 'info')
    }
}

export const getRemoteDataError = (error, fields, format = true) => {
    try {
        console.error(error.code, error.response)
        const { data } = error.response
        let errors = ''

        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('non_field_errors')) {
            if (format) {
                errors += `<li>${data.non_field_errors.toString()}</li>`
            } else {
                errors += data.non_field_errors.toString()
            }
        }

        fields.forEach(f => {
            const {title, key} = f
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty(key)) {
                if (format) {
                    errors += `<li><strong>${title}</strong>: ${data[key].toString()}</strong></li>`
                } else {
                    errors += data[key].toString()
                }
            }
        })

        return format ? `<ul>${errors}</ul>` : errors
    }
    catch (err) {
       return ''
    }
}

export const onDeleteAccents = (value) => {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const downloadFile = (url, name) => {
    const link = document.createElement('a')
    link.href = url
    link.download = name
    link.target = '_blank'
    link.click()
}
