import {v4 as uuidv4} from "uuid";
import coreApi from "@/api/coreApi";


export const tableSelected = async ({ commit, getters }, {table}) => {
    try {
        commit('isLoading', true)
        commit('tableSelected', table)

        const endpoint = getters['getEndpoint']
        const { data, status } = await coreApi.get( `${endpoint}/table/${table}/`)
        if (status === 200) {
            commit('updateOrder', data)
        }

    } catch (error) {
        console.log(error)

    } finally {
        commit('isLoading', false)
    }
}

export const addDetail = async ({ commit, getters }, { amount, product, description }) => {
    const detailSelected = getters['getDetailSelected']

    if (detailSelected) {
        detailSelected.amount = amount
        detailSelected.description = description
    } else {
        const detail = {
            id: uuidv4(),
            product: product,
            amount: amount || 1,
            description: description,
            is_confirm: false
        }
        commit('addDetail', detail)
    }
}

export const setDetail = async ({ commit }, { detail }) => commit('setDetail', detail)

export const detailDelete = async ({ commit }, detailId) => {
    commit('deleteDetail', detailId)
}

export const save = async({ commit, getters }) => {
    try {
        commit('isLoading', true)

        const endpoint = getters['getEndpoint']
        const order = getters['getItem']
        const { id } = order
        const payload = {
            table: order.table,
            details: order.details.filter(d => !d.is_confirm).map(d => ({
                amount: d.amount,
                description: d.description,
                product_id: d.product.id
            }))
        }

        const { data } = !id
            ? await coreApi.post(`${endpoint}/`, payload)
            : await coreApi.put( `${endpoint}/${id}/`, payload)

        commit('updateOrder', data)
        return {ok: true, msg: `Orden guardada correctamente!`}

    } catch (error) {
        return {ok: false, msg: 'No se pudo guardar la orden.'}

    } finally {
        commit('isLoading', false)
    }
}

export const updateStatus = async({ commit, getters }, {id, status}) => {
    try {
        commit('isLoading', true)

        const endpoint = getters['getEndpoint']
        const payload = { status: status }

        await coreApi.patch( `${endpoint}/${id}/update-status/`, payload)
        commit('deleteOrder', id)
        return {ok: true, msg: `Orden actualizada correctamente!`}

    } catch (error) {
        console.log(error)
        return {ok: false, msg: 'No se pudo actualizar la orden.'}

    } finally {
        commit('isLoading', false)
    }
}

export const invoice = async({ commit, getters }) => {
    try {
        commit('isLoading', true)

        const endpoint = getters['getEndpoint']
        const order = getters['getItem']
        const { id } = order
        const payload = {
            table: order.table,
            status: 'FINISHED',
            details: order.details.filter(d => !d.is_confirm).map(d => ({
                amount: d.amount,
                description: d.description,
                product_id: d.product.id
            }))
        }

        if (id) {
            await coreApi.put(`${endpoint}/${id}/`, payload)
        } else {
            await coreApi.post(`${endpoint}/`, payload)
        }

        commit('terminateOrder', order.table)
        return {ok: true, msg: `Orden terminada!`}

    } catch (error) {
        console.log(error)
        return {ok: false, msg: 'No se pudo terminar la orden.'}

    } finally {
        commit('isLoading', false)
    }
}

export const loadItems = async ({ commit, getters }, status= []) => {
    try {
        commit('isLoading', true)
        commit('setItems', [])

        const endpoint = getters['getEndpoint']
        const { data } = await coreApi.get(`${endpoint}/find-all/?format=json&status=${status.toString()}`)

        if (data) {
            commit('setItems', data)
        }
    } catch (error) {
        commit('setItems', [])

    } finally {
        commit('isLoading', false)
    }

}

export const addInvoice = async ({ commit }, { detail, amount, to_invoice}) => {
    commit('addInvoice', {detail, amount, to_invoice})
}
