import coreApi from '@/api/coreApi'
import {getRemoteDataError} from "@/helpers/utils";

export const loadItems = async ({ commit, getters }, page = 1) => {
    try {
        commit('cleanItemSelected') // Limpia el item seleccionado
        commit('isLoading', true)

        const query = getters['getQuery']
        const endpoint = getters['getEndpoint']

        let order = ''
        const sorter = query.sorter
        if (sorter && sorter.order) {
            order = `&ordering=${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`
        }

        const active = query.filter || ''
        const search = query.search ? `&search=${query.search}` : ''
        let createdAt = query.createdAt || ''
        let optionalQueryString = `&${query.optionalQueryString}` || ''
        const { data } = await coreApi.get(`${endpoint}?format=json${optionalQueryString}&page=${page}${search}${order}${active}${createdAt}`)

        if (data) {
            commit('setItems', {data, page})
        }
    } catch (error) {
        commit('setItems', {data: {}, page: 1})

    } finally {
        commit('isLoading', false)
    }
}

export const loadItemsAll = async ({ commit, getters }) => {
    try {
        commit('isLoading', true)
        const endpoint = getters['getEndpoint']

        const { data } = await coreApi.get(`${endpoint}/find-all/?format=json`)

        if (data) {
            commit('setItemsAll', data)
        }

    } catch (error) {
        commit('setItemsAll', [])

    } finally {
        commit('isLoading', false)
    }
}

export const loadItem = async ({ commit, getters }, id) => {
    try {
        commit('isLoading', true)

        const endpoint = getters['getEndpoint']
        const { data } = await coreApi.get(`${endpoint}/${id}`)

        if (data) {
            commit('setItem', data)
        }
    } catch (error) {
        commit('setItem', null)

    } finally {
        commit('isLoading', false)
    }
}

export const saveItem = async ({ commit, getters }, {item, close} ) => {
    try {
        commit('isLoading', true)

        // const { id, name, code } = item
        const { id } = item
        const payload = { ...item }
        const endpoint = getters['getEndpoint']

        const { data } = !id
            ? await coreApi.post(`${endpoint}/`, payload)
            : await coreApi.put( `${endpoint}/${id}/`, payload )

        commit('cleanItemSelected')
        await loadItems({commit, getters}, 1)

        if (!close) {
            commit('setItem', data)
            commit('setShowCreateOrEdit', true)
        }
        return {
            ok: true,
            msg: `Registro [${data.name||data.description||data.date_at||data.username||data.id||''}] guardado correctamente!`,
            item: data
        }

    } catch (error) {
        const errors = getRemoteDataError(error, getters['getMapModelValidation'])
        return {ok: false, msg: `<strong>No se pudo completar la acción</strong>: ${errors}`}

    } finally {
        commit('isLoading', false)
    }
}


export const print = async ({ commit, getters }, id ) => {
    try {
        commit('isLoading', true)
        const endpoint = getters['getEndpoint']
        await coreApi.get( `${endpoint}/${id}/print`)
        return {ok: true, msg: 'Impresión realizada correctamente!'}

    } catch (error) {
        return {ok: false, msg: 'No se pudo realizar la impresión'}

    } finally {
        commit('isLoading', false)
    }
}

export const updateStatus = async ({ commit, getters }, {id, status}) => {
    try {
        commit('isLoading', true)
        const endpoint = getters['getEndpoint']
        const payload = { status }
        const { data } = await coreApi.patch(`${endpoint}/${id}/status/`, payload)

        if (data) {
            commit('setItem', data)
        }

        return {
            ok: true,
            msg: 'Proceso realizado correctamente.'
        }

    } catch (error) {
        return {
            ok: false,
            msg: 'No se pudo realizar el proceso.'
        }

    } finally {
        commit('isLoading', false)
    }
}

export const setItem = async ({ commit }, item) => {
    commit('setItem', item)
    commit('setShowCreateOrEdit', true)
}

export const setQuerySearch = async ({ commit, getters }, term) => {
    commit('setQuerySearch', term)
    await loadItems({commit, getters}, 1)
}

export const setQuerySorter = async ({ commit, getters }, term) => {
    commit('setQuerySorter', term)
    await loadItems({commit, getters}, 1)
}

export const setQueryFilter = async ({ commit, getters }, term) => {
    commit('setQueryFilter', term)
    await loadItems({commit, getters}, 1)
}

export const setQueryCreatedAt = async ({ commit, getters }, term) => {
    commit('setQueryCreatedAt', term)
    await loadItems({commit, getters}, 1)
}

export const setCreateOrEdit = async ({ commit }, value) => {
    commit('setShowCreateOrEdit', value)
}

export const setOptionalQueryString = async ({ commit }, query) => {
    commit('setOptionalQueryString', query)
}
