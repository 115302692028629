import { pagination, query } from '@/helpers/paginationUtils'

export default () => ({
    endpoint: '/v1/product-types',
    isLoading: true,
    items: {},
    item: null,
    isCreateOrEdit: false,
    mapModelValidation: [
        { title: 'Nombre', key: 'name' },
        { title: 'Código', key: 'code' }
    ],
    pagination: pagination(),
    query: query()
})
