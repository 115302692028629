import coreApi from "@/api/coreApi";

export const loadDetailsByInventoryMovement = async ({ commit, getters }, inventoryMovementId) => {
    try {
        commit('isLoading', true)
        const endpoint = getters['getEndpoint']

        const { data } = await coreApi.get(`${endpoint}/find-all/?format=json&inventory_id=${inventoryMovementId}`)

        if (data) {
            commit('setItemsAll', data)
        }

    } catch (error) {
        commit('setItemsAll', [])

    } finally {
        commit('isLoading', false)
    }
}
