export default {

    name: 'report',
    path: 'reports',
    children: [
        {
            path: 'invoice',
            name: 'invoice-report',
            component: () => import(/* webpackChunkName: "InvoiceReport" */ '@/modules/report/views/InvoiceReportView.vue'),
        },
        {
            path: 'iva',
            name: 'iva-report',
            component: () => import(/* webpackChunkName: "IVAReport" */ '@/modules/report/views/IvaReportView.vue'),
        },
        {
            path: 'inventory',
            name: 'inventory-report',
            component: () => import(/* webpackChunkName: "InventoryReport" */ '@/modules/report/views/InventoryReportView.vue'),
        },
        {
            path: 'sale',
            name: 'sale-report',
            component: () => import(/* webpackChunkName: "InventoryReport" */ '@/modules/report/views/SaleReportView.vue'),
        }
    ]

}
