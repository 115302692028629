import {createRouter, createWebHashHistory} from 'vue-router'
import isAuthenticatedGuard from '@/modules/auth/router/auth-guard'
import HomeView from '@/modules/home/views/HomeView.vue'
import authRouter from '@/modules/auth/router'
import productRouter from '@/modules/product/router'
import productTypeRouter from '@/modules/product-type/router'
import customerProviderRouter from '@/modules/customer-provider/router'
import invoiceRouter from '@/modules/invoice/router'
import warehouseRouter from '@/modules/warehouse/router'
import reportRouter from '@/modules/report/router'
import userRouter from '@/modules/user/router'
import discountRouter from '@/modules/discount/router'
import orderRouter from '@/modules/order/router'
import makeRouter from '@/modules/make/router'
import companyRouter from '@/modules/company/router'
import docElectRouter from '@/modules/doc-elect/router'
import inventoryRouter from '@/modules/inventory/router'

const routes = [
    {
        path: '/',
        beforeEnter: [isAuthenticatedGuard],
        name: 'home',
        component: HomeView,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import(/* webpackChunkName: "DashboardView" */ '@/modules/home/views/DashboardView.vue'),
            },
            {
                path: '/company',
                ...companyRouter,
            },
            {
                path: '/products',
                ...productRouter,
            },
            {
                path: '/product-types',
                ...productTypeRouter,
            },
            {
                path: '/customers-providers',
                ...customerProviderRouter
            },
            {
                path: '/invoices',
                ...invoiceRouter
            },
            {
                path: '/warehouses',
                ...warehouseRouter
            },
            {
                path: '/reports',
                ...reportRouter
            },
            {
                path: '/users',
                ...userRouter
            },
            {
                path: '/discounts',
                ...discountRouter
            },
            {
                path: '/orders',
                ...orderRouter
            },
            {
                path: '/makes',
                ...makeRouter
            },
            {
                path: '/doc-elect',
                ...docElectRouter
            },
            {
                path: '/inventories',
                ...inventoryRouter
            }
        ]
    },
    {
        path: '/auth',
        beforeEnter: [isAuthenticatedGuard],
        ...authRouter,
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
