export const status = (state, { status }) => {
    state.status = status
}

export const loginUser = ( state, { token, user }) => {
    state.t = user
    if ( token ) {
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))

        state.token = token
        state.user = user
        state.status = 'authenticated'

        if (user.warehouses && user.warehouses.length > 0) {
            const warehouse = localStorage.getItem('warehouse')
            if (warehouse) {
                updateWarehouseCurrent(state, parseInt(warehouse))
            } else {
                updateWarehouseCurrent(state, user.warehouses[0].id)
            }
        }
    }
}


export const logout = (state) => {

    state.user = null
    state.token = null
    state.status = 'not-authenticated'

    localStorage.removeItem('token')
    localStorage.removeItem('user')
    // localStorage.removeItem('warehouse')
}

export const updateWarehouseCurrent = (state, warehouseId) => {
    const { user } = state

    if (user && user.warehouses && user.warehouses.length > 0) {
        const warehouse = user.warehouses.find(w => w.id === warehouseId)
        if (warehouse) {
            state.warehouseCurrent = warehouse
            localStorage.setItem('warehouse', state.warehouseCurrent.id)
        }
    }
}

export const theme = (state, theme) => {
    localStorage.setItem('theme', theme)
    state.theme = theme
}
