import * as momentz from 'moment-timezone';
import 'moment/locale/es';

export const dateHumanize = (date) => {
    return momentz.tz(date, Intl.DateTimeFormat().resolvedOptions().timeZone).fromNow(true);
}

export const dateF = (date) => {
    const d = new Date(date)
    return momentz.tz(d, Intl.DateTimeFormat().resolvedOptions().timeZone).format('L');
}

export const datetimeF = (date) => {
    const d = new Date(date)
    return momentz.tz(d, Intl.DateTimeFormat().resolvedOptions().timeZone).format('L hh:mm a');
}

export const dateUs = (date) => {
    const d = new Date(date)
    return`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
}
