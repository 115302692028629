import { pagination, query } from '@/helpers/paginationUtils'

export default () => ({
    endpoint: '/v1/invoices-accounting-registers',
    isLoading: true,
    items: {
        results: []
    },
    item: null,
    isCreateOrEdit: false,
    mapModelValidation: [
        { title: 'Fecha', key: 'dateAt' },
        { title: 'Moneda', key: 'currency_id' }
    ],
    pagination: pagination(),
    query: query()
})
