import authApi from '@/api/authApi'

export const signInUser = async ({ commit }, { username, password } ) => {

    try {

        commit('status', { status: 'authenticating' })
        const { data } = await authApi.post('/api-token-auth/', { username, password })
        const { token, user } = data

        commit('loginUser', { token, user })

        return { ok: true }

    } catch (error) {
        commit('status', { status: 'not-authenticated' })
        return { ok: false, msg: error.response.data.non_field_errors[0] }
    }

}

export const checkAuthentication = async ({ commit }) => {

    const token = localStorage.getItem('token')
    const userStr = localStorage.getItem('user')

    if( !token ) {
        commit('logout')
        return { ok: false, msg: 'No hay token' }
    }

    const user = JSON.parse(userStr)
    commit('loginUser', { user, token })
    return { ok: true }
}
