import { pagination, query } from '@/helpers/paginationUtils'

export default () => ({
    endpoint: '/v1/inventories/movements',
    isLoading: false,
    items: {
        results: []
    },
    item: null,
    isCreateOrEdit: false,
    mapModelValidation: [
        { title: 'Descripción', key: 'description' }
    ],
    pagination: pagination(),
    query: query()
})
