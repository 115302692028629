import { pagination, query } from '@/helpers/paginationUtils'

export default () => ({
    endpoint: '/v1/warehouses',
    isLoading: true,
    items: {
        results: []
    },
    item: null,
    isCreateOrEdit: false,
    mapModelValidation: [
        { title: 'Nombre', key: 'name' },
        { title: 'Descripción', key: 'description' },
        { title: 'Código sucursal Hacienda', key: 'code_treasury' }
    ],
    pagination: pagination(),
    query: query()
})
