<template>
  <n-config-provider :theme="theme">
    <n-notification-provider>
      <n-dialog-provider>
        <n-message-provider>
            <div v-if="authStatus === 'authenticating'">
              <n-skeleton text :repeat="2" />
              <n-skeleton text style="width: 60%" />
            </div>
            <!--
            <router-view v-else v-slot="{Component, route}">
              <keep-alive>
                <component :is="Component" :key="route.name" />
              </keep-alive>
            </router-view>
            -->
          <router-view></router-view>
        </n-message-provider>
      </n-dialog-provider>
    </n-notification-provider>
  </n-config-provider>
</template>

<script>
import { defineComponent } from "vue";
import useAuth from '@/modules/auth/composables/useAuth'

export default defineComponent({
  setup() {

    const { authStatus, checkAuthStatus, theme } = useAuth()
    checkAuthStatus()

    return {
      authStatus,
      theme
    }
  }
})
</script>
