import { createStore } from 'vuex'

import auth from '@/modules/auth/store'
import currency from '@/modules/currency/store'
import productType from '@/modules/product-type/store'
import product from '@/modules/product/store'
import customerProvider from '@/modules/customer-provider/store'
import invoice from '@/modules/invoice/invoice/store'
import invoiceHistory from '@/modules/invoice/history/store'
import invoiceAccountingRegister from '@/modules/invoice/accounting-register/store'
import warehouse from '@/modules/warehouse/store'
import user from '@/modules/user/store'
import discount from '@/modules/discount/store'
import order from '@/modules/order/store'
import make from '@/modules/make/store'
import company from '@/modules/company/store'
import docElect from '@/modules/doc-elect/store'
import report from '@/modules/report/store'
import inventoryMovement from '@/modules/inventory/inventory-movement/store'
import inventoryMovementDetail from '@/modules/inventory/inventory-movement-detail/store'

const store = createStore({
  modules: {
      auth,
      company,
      currency,
      productType,
      product,
      customerProvider,
      invoice,
      invoiceHistory,
      invoiceAccountingRegister,
      warehouse,
      user,
      discount,
      order,
      make,
      docElect,
      report,
      inventoryMovement,
      inventoryMovementDetail
  }
})

export default store
