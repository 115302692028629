
export default () => ({
    items: [
        {
            label: 'Colónes (CRC)',
            value: 1,
            code: 'CRC',
            symbol: '₡',
            id: 1
        },
        {
            label: 'Dólares (USD)',
            value: 2,
            code: 'USD',
            symbol: '$',
            id: 2
        }
    ]
})
