import {v4 as uuidv4} from "uuid";
import coreApi from '@/api/coreApi'
import { formatNumber } from '@/helpers/numberUtils'
import {getRemoteDataError} from "@/helpers/utils";

export const addDetail = async ({ commit }, { amount, product, price, order }) => {
    const detail = {
        id: uuidv4(),
        product: product,
        price: price || product.sale_price,
        amount: amount || 1,
        discount: 0,
        subtotal: 0,
        tax: 0,
        total: 0,
        order: null
    }

    if (order) {
        detail.order = {
            id: order.id,
            amount_by_invoice: amount
        }
    }

    commit('addDetail', detail)
}

export const removeDetail = async ({ commit }, detail) => commit('removeDetail', detail)
export const removeDetailByOrder = async ({ commit }, orderId) => commit('removeDetailByOrder', orderId)

export const updateDetailSelected = async ({ commit }, detail) => commit('updateDetailSelected', detail)

export const updateCurrency = async ({ commit }, currency) => commit('updateCurrency', currency)

export const updateSaleCondition = async ({ commit }, saleCondition) => commit('updateSaleCondition', saleCondition)

export const updateCreditTerm = async ({ commit }, creditTerm) => commit('updateCreditTerm', creditTerm)

export const updateAmount = async ({ commit }, amount) => commit('updateDetailAmount', amount)

export const updatePrice = async ({ commit }, price) => commit('updatePrice', price)

export const updateDiscount = async ({ commit }, { discount, isApplyToPrice }) =>
    commit('updateDiscount', { discount, isApplyToPrice })

export const updateCustomer = async  ({ commit }, customer) => commit('updateCustomer', customer)

export const updatePaymentMethods = async  ({ commit }, paymentMethods) => commit('updatePaymentMethods', paymentMethods)

export const updateInvoiceType = async  ({ commit }, is_proforma) => commit('updateInvoiceType', is_proforma)

export const clearData = async ({ commit }) => commit('clearData')

export const setProformaToInvoice = async ({ commit }, proforma) => commit('setProformaToInvoice', proforma)

export const create = async ({ commit, getters }, warehouseId) => {
    try {
        commit('isLoading', true)

        const {
            sale_condition,
            is_proforma,
            credit_term,
            customer,
            currency,
            payment_methods,
            details
        } = getters['getInvoice']

        const proforma = getters['getProformaToInvoice']

        if (sale_condition === '02' && (!customer || !sale_condition)) {
            return {
                ok: false,
                msg: `Debe agregar el cliente y el plazo para faturas a crédito.`
            }
        }

        const payload = {
            warehouse: warehouseId,
            is_proforma: is_proforma,
            proforma_id: null,
            sale_condition: sale_condition,
            currency_id: currency.id,
            payment_methods: payment_methods.map(p => ({
                code: p.code,
                total: p.total.toFixed(2)
            })),
            details: details.map(d => ({
                product: d.product.id,
                amount: d.amount,
                price: d.price,
                discount: d.discount,
                order: d.order
            }))
        }

        if (customer) {
            payload['customer_id'] = customer.id
        }
        if (credit_term) {
            payload['credit'] = { credit_term: credit_term }
        }
        if (proforma) {
            payload.proforma_id = proforma.id
        }

        const { data } = await coreApi.post('v1/invoices/', payload)

        commit('clearData')
        const customerChange = `Su cambio ${data.currency.symbol}${formatNumber(data.customer_change)}`
        return {ok: true, msg: `${ !data.is_proforma ? 'Factura' : 'Proforma'} [No.${data.number}] creada correctamente! ${customerChange}`}

    } catch (error) {
        const errors = getRemoteDataError(error, getters['getMapModelValidation'], false)
        return {ok: false, msg: `No se pudo crear la factura: ${errors}`}

    } finally {
        commit('isLoading', false)
    }
}

export const loadItem = async ({ commit }, id) => {
    try {
        if (!id) return

        commit('isLoading', true)
        const { data } = await coreApi.get(`v1/invoices/${id}`)

        if (data) {
            commit('setItem', data)
            await loadDetails({commit}, id)
        }
    } catch (error) {
        commit('setItem', null)

    } finally {
        commit('isLoading', false)
    }
}

export const loadDetails = async ({ commit }, id) => {
    try {
        commit('isLoadingDetails', true)

        const { data } = await coreApi.get(`v1/invoices/${id}/details`)

        if (data) {
            commit('setDetails', data)
        }

    } catch (error) {
        return {
            ok: false,
            msg: 'No se pudo cargar los detalles.'
        }

    } finally {
        commit('isLoadingDetails', false)
    }
}

export const print = async ({ commit }, id) => {
    try {
        commit('isLoading', true)
        await coreApi.get(`v1/invoices/${id}/print`)

        return {
            ok: true,
            msg: 'Impresión realizada correctamente.'
        }

    } catch (error) {
        return {
            ok: false,
            msg: 'No se realizar la impresión.'
        }

    } finally {
        commit('isLoading', false)
    }
}

export const sendMail = async ({ commit }, id) => {
    try {
        commit('isLoading', true)
        await coreApi.get(`v1/invoices/${id}/send-mail`)

        return {
            ok: true,
            msg: 'Email enviado correctamente.'
        }

    } catch (error) {
        return {
            ok: false,
            msg: 'No se pudo enviar el email.'
        }

    } finally {
        commit('isLoading', false)
    }
}

export const updateStatus = async ({ commit }, {id, status}) => {
    try {
        commit('isLoading', true)
        const payload = { status }
        const { data } = await coreApi.patch(`v1/invoices/${id}/status/`, payload)

        if (data) {
            commit('setItem', data)
        }

        return {
            ok: true,
            msg: 'Proceso realizado correctamente.'
        }

    } catch (error) {
        return {
            ok: false,
            msg: 'No se pudo realizar el proceso.'
        }

    } finally {
        commit('isLoading', false)
    }
}


export const updateToPaid = async ({ commit }, {id, payment_methods}) => {
    try {
        commit('isLoading', true)
        const payload = { status, payment_methods }
        const { data } = await coreApi.patch(`v1/invoices/${id}/paid/`, payload)

        if (data) {
            commit('setItem', data)
        }

        return {
            ok: true,
            msg: 'Proceso realizado correctamente.'
        }

    } catch (error) {
        return {
            ok: false,
            msg: 'No se pudo realizar el proceso.'
        }

    } finally {
        commit('isLoading', false)
    }
}

export const setControlFocus = async ({commit}, control) => commit('setControlFocus', control)

export const onControlFocus = async ({commit}) => commit('onControlFocus')
