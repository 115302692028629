export default {
    name: 'invoice',
    path: 'invoices',
    children: [
        {
            path: '',
            name: 'invoice-create',
            component: () => import(/* webpackChunkName: "InvoiceCreate" */ '@/modules/invoice/views/InvoiceCreateView.vue'),
        },
        {
            path: 'history',
            name: 'invoice-history',
            component: () => import(/* webpackChunkName: "InvoiceHistory" */ '@/modules/invoice/views/InvoiceHistoryView.vue'),
        },
        {
            path: 'proformas',
            name: 'proforma',
            component: () => import(/* webpackChunkName: "ProformaView" */ '@/modules/invoice/views/ProformaView.vue'),
        },
        {
            path: 'detail/:id:',
            name: 'invoice-detail',
            component: () => import(/* webpackChunkName: "InvoiceDetail" */ '@/modules/invoice/views/InvoiceDetailView.vue'),
        },
        {
            path: 'accounting-register',
            name: 'invoice-accounting-register',
            component: () => import(/* webpackChunkName: "InvoiceAccountRegister" */ '@/modules/invoice/views/InvoiceAccountingRegisterView.vue'),
        }
    ]
}
