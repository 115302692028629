export default () => ({
    isLoading: false,
    invoice: {
        warehouse: 1,
        is_proforma: false,
        currency: null,
        sale_condition: '01',
        credit_term: null,
        customer: null,
        payment_methods: [],
        details: [],
        discount: 0,
        subtotal: 0,
        tax: 0,
        total: 0
    },
    item: null,
    proformaToInvoice: null,
    detailSelected: null,
    details: {
        isLoading: false,
        items: null
    },
    mapModelValidation: [
        { title: 'Moneda', key: 'currency_id' },
        { title: 'Detalle', key: 'detail' },
    ],
    controlFocus: null
})
