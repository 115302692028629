import coreApi from "@/api/coreApi";

export const genReport = async ({ commit }, {report, payload}) => {

    try {
        commit('isLoading', true)
        const { data } = await coreApi.post(`v1/reports/${report}`, payload)

        if (data.report_base64) {
            commit('setItem', data)
            downloadReport(data.report_base64, data.filename, data.report_type)
            return {ok: true, msg: `Reporte generado correctamente!`}
        } else {
            return {ok: true, msg: `No hay registros con los parámetros enviados.`}
        }

    } catch (error) {
        commit('setItem', null)
        return {ok: false, msg: `No se pudo generar el reporte`}

    } finally {
        commit('isLoading', false)
    }

}

const downloadReport = (fileBase64, filename, report_type) => {
    let linkSource = ''

    switch (report_type) {
        case 'pdf':
            linkSource = `data:application/pdf;base64,${fileBase64}`
            break
        case 'csv':
            linkSource = `data:application/csv;base64,${fileBase64}`
            break
        default:
            return
    }

    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = filename
    downloadLink.click()
}

